import React, { useEffect, useState } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import styles from "./styles.module.css";
import Gallery from "src/components/molecules/Gallery";
import Schedule from "src/components/atoms/Schedule";
import Addvert from "src/components/atoms/Addvertisment";
import FastTicketBuy from "src/components/atoms/FastTicketBuy";
import HomePicture from "src/components/atoms/HomePicture";
import Footer from "src/components/molecules/Footer";
import { Box } from "@mui/material";
import MainMiddleContent from "src/components/atoms/MainMiddleContent";
import VisitInfo from "src/components/molecules/VisitInfo";
import ModalSchedule from "src/components/atoms/ModalSchedule/ModalSchedule";
import InfoComponent from "src/components/atoms/FAQ";
import { MainMiddleContentDesktop } from "src/components/atoms/MainMiddleContentDesktop";
import { GalleryDesktopMain, GalleryDesktopSub } from "src/components/molecules/GalleryDesktop";
import { useTranslation } from "react-i18next";
import HomePictureDesktop from "src/components/atoms/HomePictureDesktop";
import TowerComponent from "src/components/atoms/TowerComponent";
import TowerComponentMobile from "src/components/atoms/TowerComponentMobile";

interface PageHomeProps {
  PurchseTiketsClick: () => void;
  VisitClick: () => void;
  FooterRef: React.RefObject<HTMLDivElement>;
  VisitRef: React.RefObject<HTMLDivElement>;
  VisitDesktopDesktop: React.RefObject<HTMLDivElement>;
}

export const PageHome = ({
  PurchseTiketsClick,
  FooterRef,
  VisitRef,
  VisitDesktopDesktop,
}: PageHomeProps) => {
  const [transportInfo, setTransportInfo] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1023);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onModalOpen = () => {
    setTransportInfo(true);
  };

  const onModalClose = () => {
    setTransportInfo(false);
  };

  return (
    <Box
      className={styles.wrapper}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {isDesktop ? (
        <>
          <MainMiddleContentDesktop>
            <HomePictureDesktop />
            <TowerComponent />
            <div ref={VisitDesktopDesktop}>
              <Addvert background="#F8F8F8" />
            </div>
            <GalleryDesktopMain />
            <GalleryDesktopSub />
            <Schedule onVisible={onModalOpen} />
            <InfoComponent header="FAQ" mainText={t("faq_header")} navigateTo="faq" />
            <InfoComponent
              header={t("discover_church")}
              mainText={t("show_map")}
              navigateTo="map"
            />
            {transportInfo && <ModalSchedule onClose={onModalClose} />}
          </MainMiddleContentDesktop>
          <Footer ref={FooterRef} />
        </>
      ) : (
        <>
          <HomePicture purchseTiketsClick={PurchseTiketsClick} />
          <MainMiddleContent>
            <VisitInfo />
            <TowerComponentMobile />
            <Gallery />
            <Schedule onVisible={onModalOpen} />
            <InfoComponent header="FAQ" mainText={t("faq_header")} navigateTo="faq" />
            <InfoComponent
              header={t("discover_church")}
              mainText={t("show_map")}
              navigateTo="map"
            />
            <Footer ref={FooterRef} />
            <FastTicketBuy />
            {transportInfo && <ModalSchedule onClose={onModalClose} />}
          </MainMiddleContent>
        </>
      )}
    </Box>
  );
};
