import styled from "styled-components";

export const Wrapper = styled.div`
  width: 1360px;
  height: 1100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding: 16px;
  border-radius: 10px;
  margin: 100px 0;
  background: #f8f8f8;
`;

export const TowerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 560px;
`;

export const TowerImage = styled.img`
  width: 548px;
  height: 557px;
  border-radius: 10px;
  opacity: 0px;
`;

export const TowerTextWrapper = styled.div`
  width: 760px;
  height: 557px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 30px;
  margin-top: 50px;
`;

export const TowerTitle = styled.span`
  font-family: Montserrat, serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #062762;
  margin-top: 10px;
  margin-bottom: 50px;
`;

export const BaseTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-shrink: 1;
  margin-bottom: 50px;
`;

export const BaseText = styled.span`
  font-family: Montserrat, serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.38px;
  text-align: left;
  color: #000000;
  margin: 10px 0;
`;

export const ListWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: #000000;
`;

export const ScheduleTitle = styled.span`
  font-family: Montserrat, serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24.38px;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
`;

export const Schedule = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 0.3fr 1fr;
`;

export const ScheduleItem = styled.div`
  padding: 26px 26px 26px 56px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  &:not(:nth-last-child(-n + 2)) {
    border-bottom: 1px solid red;
  }
  &:nth-child(odd) {
    border-right: 1px solid red;
  }
`;

export const ScheduleText = styled.span`
  font-family: Montserrat, serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #e31340;
`;

export const TicketsWrapper = styled.div`
  width: 100%;
  height: 450px;
  padding-left: 10px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

export const StairsImage = styled.img`
  width: 487px;
  height: 449px;
  border-radius: 10px;
  opacity: 0px;
`;

export const TicketsContent = styled.div`
  width: 100%;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 7px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const PriceTitle = styled.span`
  font-family: Montserrat, serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 36.57px;
  text-align: left;
  color: #062762;
`;

export const PriceDescription = styled.span`
  font-family: Montserrat, serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.38px;
  text-align: left;
  color: #062762;
`;

export const TicketsTypeWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, 365px);
  place-items: flex-start;
  gap: 10px;
`;

export const TicketBlock = styled.div`
  width: 365px;
  height: 55px;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #79747e;
  background: #ffffff;
`;

export const TicketDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const TicketBlockTitle = styled.span`
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #000000;
`;

export const TicketBlockDescription = styled.span`
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  color: #000000;
`;

export const TicketPrice = styled.span`
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-align: right;
  color: #e31340;
`;

export const WarningText = styled.span`
  font-family: Montserrat, serif;
  margin-top: 20px;
  margin-bottom: 80px;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #e31340;
`;

export const ScheduleTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
