import styled from "styled-components";

export const Wrapper = styled.div`
  width: 90vw;
  min-height: 1600px;
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 20px;
  background: #f8f8f8;
`;

export const TowerWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TowerHead = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 28px;
  margin-bottom: 20px;
`;

export const Image = styled.img`
  width: 129px;
  height: 151px;
  border-radius: 10px;
`;

export const TowerTitle = styled.span`
  font-family: Montserrat, serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 26.82px;
  text-align: left;
  color: #062762;
`;

export const TextWrapper = styled.div`
  width: 100%;
  padding: 0 6px;
`;

export const BaseText = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: #000000;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: -20px;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;

  li::marker {
    color: #000;
    font-size: 0.5em;
  }
`;

export const ScheduleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
`;

export const ScheduleTitle = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  text-align: left;
  color: #000;
`;

export const Schedule = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  margin-top: 10px;
`;

export const ScheduleItem = styled.div`
  font-size: 16px;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  &:not(:nth-last-child(-n + 2)) {
    border-bottom: 1px solid red;
  }
  &:nth-child(odd) {
    border-right: 1px solid red;
  }
`;

export const ScheduleText = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #e31340;
`;

export const TicketsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TicketsHead = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
`;

export const TicketsTitle = styled.span`
  font-family: Montserrat, serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24.38px;
  text-align: left;
  color: #062762;
`;

export const TicketsSubTitle = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: #062762;
`;

export const TicketWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
`;

export const Ticket = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 5px;
  padding: 0 20px 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #79747e;
  background: #ffffff;
`;

export const TicketDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const TicketTitle = styled.span`
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #000000;
`;

export const TicketDescription = styled.span`
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  color: #000000;
`;

export const TicketPrice = styled.span`
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-align: right;
  color: #e31340;
`;

export const Warning = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  text-align: left;
  margin-top: 20px;
  color: #e31340;
`;

export const StairsImage = styled.img`
  width: 100%;
  height: 291px;
  border-radius: 10px;
  margin: 20px 0;
`;
