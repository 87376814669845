import React from "react";
import {
  BaseText,
  Image,
  List,
  ListWrapper,
  Schedule,
  ScheduleTitle,
  ScheduleWrapper,
  TextWrapper,
  TowerHead,
  TowerTitle,
  TowerWrapper,
  ScheduleItem,
  ScheduleText,
  Wrapper,
  TicketsWrapper,
  TicketsHead,
  TicketsTitle,
  TicketsSubTitle,
  TicketWrapper,
  Ticket,
  TicketDescriptionWrapper,
  TicketTitle,
  TicketDescription,
  TicketPrice,
  Warning,
  StairsImage,
} from "src/components/atoms/TowerComponentMobile/styledComponents";

import photo from "../../../assets/WhatsApp Image 2024-10-25 at 14.56.27 1.png";
import secondPhoto from "../../../assets/1. zvon Panna Maria 1.png";
import { tickets } from "src/core/helpers/towerTickets";
import { useTranslation } from "react-i18next";
import { towerGuideSchedule } from "src/core/helpers/towerGuideSchedule";

const TowerComponentMobile = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <TowerWrapper>
        <TowerHead>
          <Image src={photo} />
          <TowerTitle dangerouslySetInnerHTML={{ __html: t("tower_title_mob") }} />
        </TowerHead>
        <TextWrapper>
          <BaseText>{t("tower_sub_title")}</BaseText>
          <ListWrapper>
            <List>
              <li>
                <BaseText>{t("tower_list_item_one")}</BaseText>
              </li>
              <li>
                <BaseText>{t("tower_list_item_two")}</BaseText>
              </li>
              <li>
                <BaseText>{t("tower_list_item_three")}</BaseText>
              </li>
            </List>
          </ListWrapper>
          <BaseText>{t("tower_list_sub_title")}</BaseText>
        </TextWrapper>
      </TowerWrapper>
      <ScheduleWrapper>
        <ScheduleTitle dangerouslySetInnerHTML={{ __html: t("tower_guide") }} />
        <Schedule>
          {towerGuideSchedule.map((el) => (
            <ScheduleItem key={el}>
              <ScheduleText>{t(el)}</ScheduleText>
            </ScheduleItem>
          ))}
        </Schedule>
      </ScheduleWrapper>
      <TicketsWrapper>
        <TicketsHead>
          <TicketsTitle>{t("ticket_prices")}</TicketsTitle>
          <TicketsSubTitle>{t("ticket_prices_desc")}</TicketsSubTitle>
        </TicketsHead>
        <TicketWrapper>
          {tickets.map((ticket) => (
            <Ticket key={ticket.title}>
              <TicketDescriptionWrapper>
                <TicketTitle>{t(ticket.title)}</TicketTitle>
                <TicketDescription>{t(ticket.description)}</TicketDescription>
              </TicketDescriptionWrapper>
              <TicketPrice>{`${ticket.price ? ticket.price : t("for_free")} ${
                ticket.price ? t("cz_currency") : ""
              }`}</TicketPrice>
            </Ticket>
          ))}
        </TicketWrapper>
        <Warning dangerouslySetInnerHTML={{ __html: t("warning_mob") }} />
        <StairsImage src={secondPhoto} />
        <BaseText>{t("tickets_prices_desc")}</BaseText>
      </TicketsWrapper>
    </Wrapper>
  );
};

export default TowerComponentMobile;
