import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { styles } from "./styles";
import styless from "./styles.module.css";
import ticketsClosed from "../../../assets/graytickets.svg";
import { UsePostPreReservation } from "src/core/queries/usePostPreReservation";
import { useFormValuesContext } from "src/core/context";
import { useGetTickets } from "src/core/queries/useGetTickets";
import { CheckOutStep, PreReservation, Tab } from "src/core/types";
import { useGetCapacity } from "src/core/queries/useGetCapacity";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/core/redux/store";
import ticketIcon from "../../../assets/ticket_red.svg";
import FullScreenTicketButton from "src/components/atoms/FullScreenTicketButton";
import { setReservationId } from "src/core/redux/actions/reservationActions";
import ReceiptDesktop from "src/components/atoms/ReceiptDesktop";
import { ButtonNextStep } from "src/components/atoms/ButtonNextStep";
import { ButtonArrow } from "src/components/icons/ButtonArrow";
import AmountPeopleComponent from "src/components/atoms/AmountPeopleComponent";

const initialState: State = {
  count3: 0,
  count5: 0,
  count1: 0,
  count2: 0,
  count4: 0,
  // count6: 0,
};

const keyToTicketNameMap: { [key in keyof State]: string } = {
  count3: "adult",
  count5: "family",
  count1: "baby",
  count2: "child",
  count4: "senior",
  // count6: "ztp",
};
interface TicketTypeToIdMap {
  [key: string]: number;
}

const ticketNameToTypeIdMap: TicketTypeToIdMap = {
  baby: 1,
  child: 2,
  adult: 3,
  senior: 4,
  family: 5,
  // ztp: 6,
};

interface AmountOfPeopleProps {
  updateCheckOutState: (newTabsState: Tab[], newPanelsState: CheckOutStep[]) => void;
  step: CheckOutStep;
  panels: CheckOutStep[];
  style: React.CSSProperties;
  tabs: Tab[];
  nextStep: string;
}
const AmountOfPeople: React.FC<AmountOfPeopleProps> = ({
  updateCheckOutState,
  step,
  panels,
  style,
  tabs,
  nextStep,
}) => {
  const selectedSlot = useSelector((state: RootState) => state.selectedTicket?.selectedTicket);
  const reservationId = useSelector((state: RootState) => state.reservationID?.reservationID);
  const freeCapacity = Number(sessionStorage.getItem("free_capacity")) || 0;
  const isRecapActive = panels.find((el) => el.key === "4")?.isEnabled || false;
  const isFromApp = sessionStorage.getItem("isApp");
  const { t } = useTranslation();
  const { formValues, setFormValues } = useFormValuesContext();
  const [dynamicCapacity, setDynamicCapacity] = useState<number>(0);
  const {
    refetch: refetchCapacityDate,
    isFetching: isFetchingCapacityData,
    isLoading: isLoading,
    status: capacityStatus,
  } = useGetCapacity({
    reservationId:
      String(reservationId) || String(sessionStorage.getItem("current_reservation_id")),
    pos: isFromApp ? "app-webview" : "web",
  });

  const dispatch = useDispatch();
  const [errorIncrementWithoutAdult1, setErrorIncrementWithoutAdult1] = useState<boolean>(false);
  const [errorIncrementWithoutAdult2, setErrorIncrementWithoutAdult2] = useState<boolean>(false);
  const [errorIncrementFamilyEntrance] = useState<boolean>(false);
  const [errorIncrementLimitAdult, setErrorIncrementLimitAdult] = useState<boolean>(false);
  const [errorIncrementLimitZtp, setErrorIncrementLimitZtp] = useState<boolean>(false);
  const [counterState, setCounterState] = useState(initialState);
  const [counterStateValues, setCounterStateValues] = useState(Object.values(counterState));
  const [isButtonsAvailable, setIsButtonsAvailable] = useState<boolean>(true);
  const [selectedTimeSlotDate, setSelectedTimeSlotTime] = useState(sessionStorage.getItem("date"));
  const { data: ticketTypes } = useGetTickets();

  const babyPrice = ticketTypes?.find((ticket) => ticket.name === "baby")?.price;
  const childPrice = ticketTypes?.find((ticket) => ticket.name === "child")?.price;
  const adultPrice = ticketTypes?.find((ticket) => ticket.name === "adult")?.price;
  const seniorPrice = ticketTypes?.find((ticket) => ticket.name === "senior")?.price;
  const familyPrice = ticketTypes?.find((ticket) => ticket.name === "family")?.price;
  const ticketId = sessionStorage.getItem("selectedSlotId");
  const ztpPrice = ticketTypes?.find((ticket) => ticket.name === "ztp")?.price;
  const totalTickets = Object.values(counterState).reduce((acc, count) => {
    return acc + count;
  }, 0);

  const ticketValues: string[] = [
    "adult_entrance",
    "family_entrance",
    "children",
    "children",
    "senior_entrance",
    // "ZTP",
  ];
  const descriptions: string[] = [
    "adult_condition",
    "family_condition",
    "children_type1",
    "children_type2",
    "senior_condition",
    // "ztp",
  ];
  const ticketKeys: (keyof State)[] = ["count3", "count5", "count1", "count2", "count4"];
  const prices = [adultPrice, familyPrice, babyPrice, childPrice, seniorPrice, ztpPrice];
  const errors = [
    errorIncrementLimitAdult,
    errorIncrementFamilyEntrance,
    errorIncrementWithoutAdult1,
    errorIncrementWithoutAdult2,
    null,
    errorIncrementLimitZtp,
  ];
  const errorMessages = [
    `${t("tickets_limit")} ${dynamicCapacity} ${t("places")}`,
    `${t("tickets_limit")} ${dynamicCapacity} ${t("places")}`,
    `${t("child_with_adult")}`,
    `${t("child_with_adult")}`,
    null,
    // `${t("max_count_ztp")}`,
  ];
  const totalPrice = Object.keys(counterState).reduce((acc, key) => {
    const count = counterState[key as keyof State];
    const ticketName = keyToTicketNameMap[key as keyof State];
    const pricePerTicket = Number(
      ticketTypes?.find((ticket) => ticket.name === ticketName)?.price ?? 0,
    );
    return acc + count * pricePerTicket;
  }, 0);

  // queries
  const {
    mutate: mutatePreReservation,
    mutateAsync: mutatePreReservationAsync,
    isLoading: isMutating,
  } = UsePostPreReservation();

  const isAdultOrFamilySelected =
    counterState.count3 > 0 || counterState.count5 > 0 || counterState.count4 > 0;

  const preparePreReservationData = (state: State) => {
    let totalAmountTickets = 0;
    const tickets = Object.keys(state).reduce((acc, key) => {
      const count = state[key as keyof State];
      if (count > 0) {
        totalAmountTickets += count;
        const ticketName = keyToTicketNameMap[key as keyof State];
        const typeId = ticketNameToTypeIdMap[ticketName];
        acc.push({ type: typeId, amount: count });
      }
      return acc;
    }, [] as { type: number; amount: number }[]);

    return {
      reservation: reservationId || sessionStorage.getItem("current_reservation_id"),
      tickets: tickets,
      pos: isFromApp ? "app-webview" : "web",
      date: tickets.length >= 0 ? undefined : String(selectedTimeSlotDate),
      time_slot_id: selectedSlot?.id,
    };
  };

  const handleIncrement = async (count: keyof State) => {
    if (isFetchingCapacityData || isMutating || isLoading || capacityStatus !== "success") return;
    const updateCapacity = async () => {
      const res = await refetchCapacityDate();
      if (res.data) {
        setDynamicCapacity(res.data.capacity);
      }
    };

    await updateCapacity();

    if (dynamicCapacity <= 0) {
      setErrorIncrementLimitAdult(true);
      return;
    }

    const checkAdultOrFamilySelected = () => {
      if (count === "count1" && !isAdultOrFamilySelected) {
        setErrorIncrementWithoutAdult1(true);
        return true;
      }
      if (count === "count2" && !isAdultOrFamilySelected) {
        setErrorIncrementWithoutAdult2(true);
        return true;
      }
      if (count === "count1" && isAdultOrFamilySelected) {
        setErrorIncrementWithoutAdult1(false);
      }
      if (count === "count2" && isAdultOrFamilySelected) {
        setErrorIncrementWithoutAdult2(false);
      }
      return false;
    };

    if (checkAdultOrFamilySelected()) return;

    if (count === "count5") {
      if (dynamicCapacity - 4 >= 0) {
        setCounterState((prevState: State) => ({ ...prevState, [count]: prevState[count] + 1 }));
        await mutatePreReservationAsync(preparePreReservationData(counterState));
        await updateCapacity();
      } else {
        setErrorIncrementLimitAdult(true);
      }
      return;
    }
    setCounterState((prevState: State) => ({ ...prevState, [count]: prevState[count] + 1 }));
    await mutatePreReservationAsync(preparePreReservationData(counterState));
    await updateCapacity();
  };

  const handleDecrement = async (count: keyof State) => {
    if (isFetchingCapacityData || isMutating || isLoading || capacityStatus !== "success") return;
    const updateDecrementCapacity = async () => {
      const res = await refetchCapacityDate();
      if (res.data) {
        setDynamicCapacity(res.data.capacity);
      }
    };

    await updateDecrementCapacity();
    const checkAdultOrFamilySelected = () => {
      if (count === "count1" || count === "count2") {
        const error = !isAdultOrFamilySelected;
        setErrorIncrementWithoutAdult1(count === "count1" && error);
        setErrorIncrementWithoutAdult2(count === "count2" && error);
        if (error) return true;
      }
      return false;
    };

    if (checkAdultOrFamilySelected()) return;

    const updateCounterState = (value: number) => {
      if (+counterState[count] > 0) {
        setCounterState((prevState: State) => {
          return { ...prevState, [count]: prevState[count] + value };
        });
      }
    };

    const updateCapacity = async () => {
      const res = await refetchCapacityDate();
      if (res.data) {
        setDynamicCapacity(res.data.capacity);
      }
    };

    if (count === "count5") {
      updateCounterState(-1);
      await mutatePreReservationAsync(preparePreReservationData(counterState));
      await updateCapacity();
      return;
    }

    // Main count decrement
    setCounterState((prevState: State) => {
      if (prevState[count] > 0) {
        return { ...prevState, [count]: prevState[count] - 1 };
      }
      return prevState;
    });

    await mutatePreReservationAsync(preparePreReservationData(counterState));
    await updateDecrementCapacity();
  };

  useEffect(() => {
    if (dynamicCapacity) {
      if (dynamicCapacity === 0) {
        setErrorIncrementLimitAdult(true);
      } else {
        setErrorIncrementLimitAdult(false);
      }
    }
  }, [
    freeCapacity,
    dynamicCapacity,
    counterState.count5,
    counterState.count4,
    counterState.count3,
    counterState.count2,
    counterState.count1,
  ]);

  //no children without adult check
  useEffect(() => {
    if (counterState.count3 === 0 && counterState.count5 === 0) {
      if (counterState.count1 > 0) {
        setErrorIncrementWithoutAdult1(true);
      }
      if (counterState.count2 > 0) {
        setErrorIncrementWithoutAdult2(true);
      }
      setCounterState((prevState) => ({
        ...prevState,
        count1: 0,
        count2: 0,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counterState.count3, counterState.count5]);

  useEffect(() => {
    setCounterStateValues(Object.values(counterState));
  }, [counterState]);

  useEffect(() => {
    if (counterState) {
      mutatePreReservation(preparePreReservationData(counterState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counterState]);

  useEffect(() => {
    if (selectedSlot?.id && reservationId) {
      void refetchCapacityDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counterState, selectedSlot, reservationId]);

  useEffect(() => {
    setFormValues(() => ({
      ...formValues,
      totalTickets: totalTickets,
      totalPrice: totalPrice,
    }));
    sessionStorage.setItem("totalTickets", totalTickets.toString());
    sessionStorage.setItem("totalPrice", totalPrice.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalTickets, totalPrice]);

  useEffect(() => {
    setSelectedTimeSlotTime(sessionStorage.getItem("date"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStorage.getItem("date")]);

  const updateCapacity = () => {
    refetchCapacityDate().then((res) => {
      if (res.data) {
        return setDynamicCapacity(res.data?.capacity);
      }
    });
  };

  //control every 2 seconds if there is free capaciy
  useEffect(() => {
    const intervalId = setInterval(updateCapacity, 3000);
    return () => clearInterval(intervalId);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    let totalAmountTickets = 0;
    const tickets = Object.keys(counterState).reduce((acc, key) => {
      const count = counterState[key as keyof State];
      if (count > 0) {
        totalAmountTickets += count;
        const ticketName = keyToTicketNameMap[key as keyof State];
        const typeId = ticketNameToTypeIdMap[ticketName];
        acc.push({ type: typeId, amount: count });
      }
      return acc;
    }, [] as { type: number; amount: number }[]);

    const preReservationData: PreReservation = {
      reservation:
        reservationId || String(sessionStorage.getItem("current_reservation_id")) || null,
      tickets: tickets,
      pos: isFromApp ? "app-webview" : "web",
    };

    mutatePreReservation(preReservationData);
    setFormValues({
      ...formValues,
      tickets: tickets,
    });

    const newTabsState = tabs.map((tab: Tab) => {
      if (tab.key === "3") {
        return { ...tab, isTabActive: true };
      } else if (tab.key === "2") {
        return { ...tab, isTabDone: true, isTabActive: false };
      } else {
        return { ...tab, isTabActive: false, isTabDone: false };
      }
    });

    const newState = panels.map((panel: CheckOutStep) => {
      if (panel.key === "1") {
        return { ...panel, isEnabled: false, isPen: true };
      }
      if (panel.key === "2") {
        return { ...panel, isStepFinished: true, isEnabled: false, isPen: true };
      }
      if (panel.key === "3") {
        return { ...panel, isEnabled: true };
      }
      return { ...panel, isEnabled: false, isPen: false };
    });
    updateCheckOutState(newTabsState, newState);
  };

  const penClick = () => {
    const newTabsState = tabs.map((tab: Tab) => {
      if (tab.key === "2") {
        return { ...tab, isTabActive: true, isTabDone: false };
      }
      return { ...tab, isTabActive: false, isTabDone: false };
    });
    const newState = panels.map((panel: CheckOutStep) => {
      if (panel.key === "1") {
        return { ...panel };
      }
      if (panel.key === "2") {
        return { ...panel, isEnabled: true, isStepFinished: false };
      }
      return { ...panel, isEnabled: false, isStepFinished: false };
    });
    updateCheckOutState(newTabsState, newState);
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  useEffect(() => {
    const reservationIdLocal = sessionStorage.getItem("current_reservation_id") || null;
    if (reservationIdLocal) {
      dispatch(setReservationId(reservationIdLocal));
    }
    sessionStorage.removeItem("time_slot_id");
    sessionStorage.removeItem("selectedSlotId");
    sessionStorage.removeItem("lastSubmittedSlotId");
    void refetchCapacityDate();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sessionStorage.removeItem("totalTickets");
    setCounterState(initialState);
  }, [ticketId]);

  useEffect(() => {
    setTimeout(() => {
      setIsButtonsAvailable(true);
    }, 1000);
  }, [isButtonsAvailable]);

  return (
    <Box style={style}>
      {step.isEnabled ? (
        <Box className={styless.container}>
          <Box className={styless.title_row}>
            <Box style={styles.title}>
              <img src={ticketIcon} alt="ticket" style={{ marginRight: "10px", width: "24px" }} />
              {t("choose_ticket")}
            </Box>
          </Box>
          <Box className={styless.notesBox}>
            <span className={styless.notes}>
              {t("free-capacity")}
              <b>
                {" "}
                {dynamicCapacity || dynamicCapacity === 0 ? dynamicCapacity : freeCapacity}{" "}
                {t("free-capacity-count")}
              </b>
            </span>
          </Box>
          <Box className={styless.mainBox}>
            <Box className={styless.innerBox}>
              {counterStateValues.map((value, index) => (
                <AmountPeopleComponent
                  key={index}
                  title={t(ticketValues[index])}
                  subtitle={descriptions[index]}
                  price={Number(prices[index])}
                  count={counterStateValues[index]}
                  isError={errors[index]}
                  isButtonsAvailable={isButtonsAvailable}
                  setIsButtonsAvailable={setIsButtonsAvailable}
                  isFetching={isFetchingCapacityData}
                  isMutating={isMutating}
                  status={capacityStatus}
                  isLoadingCapacity={isLoading}
                  errorMessage={errorMessages[index]}
                  increment={() => handleIncrement(ticketKeys[index])}
                  decrement={() => handleDecrement(ticketKeys[index])}
                  isPermanentMessage={ticketValues[index] === "children"}
                />
              ))}
            </Box>
            {!isMobile && (
              <Box className={styless.receipt}>
                <ReceiptDesktop />
                <ButtonNextStep
                  isActive={totalTickets !== "0" && totalTickets !== 0}
                  onClick={() => {
                    if (totalTickets !== "0" && totalTickets !== 0) {
                      onSubmit();
                    }
                  }}
                >
                  {t("contant_button")}
                  <ButtonArrow />
                </ButtonNextStep>
              </Box>
            )}
          </Box>
          <Box className={styless.rules}>
            {step.isEnabled && isMobile && (
              <FullScreenTicketButton
                nextStep={nextStep}
                onClick={Number(totalTickets) > 0 && onSubmit}
              />
            )}
          </Box>
        </Box>
      ) : (
        step.isStepFinished &&
        !isRecapActive && (
          <Box className={styless.closedBox}>
            <div className={styless.rowBox}>
              <>
                <img src={ticketsClosed} alt="closed" />
                <Typography
                  sx={{ color: "#b9b9b9", fontWeight: "600", fontSize: "15px", marginLeft: "10px" }}
                >
                  {t("count_of_tickets")}: {totalTickets}
                </Typography>
              </>
            </div>
            {step.isPen && (
              <span className={styless.pen} onClick={penClick}>
                {t("to_change")}
              </span>
            )}
          </Box>
        )
      )}
    </Box>
  );
};
AmountOfPeople.displayName = "AmountOfPeople";
export default AmountOfPeople;
