import React from "react";
import {
  BaseText,
  BaseTextWrapper,
  ListWrapper,
  PriceDescription,
  PriceTitle,
  Schedule,
  ScheduleItem,
  ScheduleText,
  ScheduleTitle,
  ScheduleTitleWrapper,
  StairsImage,
  TicketBlock,
  TicketBlockDescription,
  TicketBlockTitle,
  TicketDescriptionWrapper,
  TicketPrice,
  TicketsContent,
  TicketsTypeWrapper,
  TicketsWrapper,
  TitleWrapper,
  TowerImage,
  TowerTextWrapper,
  TowerTitle,
  TowerWrapper,
  WarningText,
  Wrapper,
} from "src/components/atoms/TowerComponent/styledComponents";

import photo from "../../../assets/WhatsApp Image 2024-10-25 at 14.56.27 1.png";
import secondPhoto from "../../../assets/1. zvon Panna Maria 1.png";
import { tickets } from "src/core/helpers/towerTickets";
import { useTranslation } from "react-i18next";
import { towerGuideSchedule } from "src/core/helpers/towerGuideSchedule";

const TowerComponent = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <TowerWrapper>
        <TowerImage src={photo} />
        <TowerTextWrapper>
          <TowerTitle>{t("tower_title")}</TowerTitle>
          <BaseTextWrapper>
            <BaseText>{t("tower_sub_title")}</BaseText>
            <ListWrapper>
              <ul>
                <li>
                  <BaseText>{t("tower_list_item_one")}</BaseText>
                </li>
                <li>
                  <BaseText>{t("tower_list_item_two")}</BaseText>
                </li>
                <li>
                  <BaseText>{t("tower_list_item_three")}</BaseText>
                </li>
              </ul>
            </ListWrapper>
            <BaseText>{t("tower_list_sub_title")}</BaseText>
          </BaseTextWrapper>
          <ScheduleTitleWrapper>
            <ScheduleTitle dangerouslySetInnerHTML={{ __html: t("tower_guide") }} />
          </ScheduleTitleWrapper>
          <Schedule>
            {towerGuideSchedule.map((el) => (
              <ScheduleItem key={el}>
                <ScheduleText>{t(el)}</ScheduleText>
              </ScheduleItem>
            ))}
          </Schedule>
        </TowerTextWrapper>
      </TowerWrapper>
      <TicketsWrapper>
        <StairsImage src={secondPhoto} />
        <TicketsContent>
          <TitleWrapper>
            <PriceTitle>{t("ticket_prices")}</PriceTitle>
            <PriceDescription>{t("ticket_prices_desc")}</PriceDescription>
          </TitleWrapper>
          <TicketsTypeWrapper>
            {tickets.map((ticket) => (
              <TicketBlock key={ticket.title}>
                <TicketDescriptionWrapper>
                  <TicketBlockTitle>{t(ticket.title)}</TicketBlockTitle>
                  <TicketBlockDescription>{t(ticket.description)}</TicketBlockDescription>
                </TicketDescriptionWrapper>
                <TicketPrice>{`${ticket.price ? ticket.price : t("for_free")} ${
                  ticket.price ? t("cz_currency") : ""
                }`}</TicketPrice>
              </TicketBlock>
            ))}
          </TicketsTypeWrapper>
          <WarningText>{t("warning")}</WarningText>
          <BaseText>{t("tickets_prices_desc")}</BaseText>
        </TicketsContent>
      </TicketsWrapper>
    </Wrapper>
  );
};

export default TowerComponent;
