export const tickets = [
  {
    title: "adult_entrance",
    price: 280,
    description: "adult_condition",
  },
  {
    title: "children",
    price: 0,
    description: "children_type1",
  },
  {
    title: "senior_entrance",
    price: 160,
    description: "senior_condition",
  },
  {
    title: "family_entrance",
    price: 600,
    description: "family_condition",
  },
];
